@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans_regular_macroman/GandhiSans-Regular-webfont.eot');
	src: url('../fonts/gandhisans_regular_macroman/GandhiSans-Regular-webfont.eot?iefix') format('eot'),
	     url('../fonts/gandhisans_regular_macroman/GandhiSans-Regular-webfont.woff') format('woff'),
	     url('../fonts/gandhisans_regular_macroman/GandhiSans-Regular-webfont.ttf') format('truetype'),
	     url('../fonts/gandhisans_regular_macroman/GandhiSans-Regular-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans_bold_macroman/GandhiSans-Bold-webfont.eot');
	src: url('../fonts/gandhisans_bold_macroman/GandhiSans-Bold-webfont.eot?iefix') format('eot'),
	     url('../fonts/gandhisans_bold_macroman/GandhiSans-Bold-webfont.woff') format('woff'),
	     url('../fonts/gandhisans_bold_macroman/GandhiSans-Bold-webfont.ttf') format('truetype'),
	     url('../fonts/gandhisans_bold_macroman/GandhiSans-Bold-webfont.svg#webfont') format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Gandhi';
	src: url('../fonts/gandhisans_italic_macroman/GandhiSans-Italic-webfont.eot');
	src: url('../fonts/gandhisans_italic_macroman/GandhiSans-Italic-webfont.eot?iefix') format('eot'),
	     url('../fonts/gandhisans_italic_macroman/GandhiSans-Italic-webfont.woff') format('woff'),
	     url('../fonts/gandhisans_italic_macroman/GandhiSans-Italic-webfont.ttf') format('truetype'),
	     url('../fonts/gandhisans_italic_macroman/GandhiSans-Italic-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: italic;
}

.about {
	main {
		padding-top:$verticalPadding*2;
	}
	.steps {
		width:60%;
		margin:0 auto;
		counter-reset: step;
		@include max-width(900px) {
			width:100%;
		}
		li {
			margin-bottom:$verticalPadding*1.5;
			counter-increment: step;
			position: relative;
			padding-left:150px;
			@include max-width(600px) {
				padding-left:0;
				padding-top:150px;
			}
			&:before {
				position: absolute;
				top:-10px;
				left:0;
				width:100px;
				height:100px;
				background:$light-orange;
				border-radius:50%;
				text-align: center;
				line-height:100px;
				content:counter(step);
				font-weight: bold;
				font-size:60px;
				border:4px solid white;
				z-index:1;
				@extend %noselect;
				@include max-width(600px) {
					top:0;
					left:50%;
					transform:translateX(-50%);
				}
			}
			&:after {
				content:"";
				position: absolute;
				width:120px;
				height:120px;
				border-radius:50%;
				background:$dark-blue;
				left: -6px;
				top:-16px;
				@extend %noselect;
				@include max-width(600px) {
					left:50%;
					top:-6px;
					transform:translateX(-50%);
				}
			}
		}
		h2 {
			@extend %heading;
			margin-bottom: .5em;
			font-size:36px;
		}
		p {
			@extend %body;
			font-size:21px;
			line-height:1.7;
			a {
				color:$dark-orange;
				font-weight: bold;
			}
		}
	}
}
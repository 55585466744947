* {
	box-sizing:border-box;
}

html {
	height:100%;
}

body {
	min-height:100%;
	font-family:Gandhi, sans-serif;
	color:$black;
	position: relative;
	padding-bottom:$footerHeight;
	overflow-x: hidden;
	@include max-width(800px){
		padding-bottom:0;
	}
}

i, em {
	font-style: italic;
}

b, strong {
	font-weight: bold;
}

sup {
	position: relative;
	top:-.5em;
	font-size:60%;
}

%container {
	max-width:1200px;
	padding:0 $horizontalPadding;
	margin:0 auto;
}

%button {
	font-family:Gandhi, sans-serif;
	text-transform: uppercase;
	padding:10px 30px;
	color:white;
	background:$light-orange;
	text-decoration: none;
	border:none;
	display: inline-block;
	cursor: pointer;
	border-radius: 3px;
	@extend %noselect;
}

%half {
	width:50%;
}

%twothirds {
	width:66.67%;
}

%onethird {
	width:33.33%
}

%grid {
	li {

	}
}

main {
	@extend %container;
	padding: $verticalPadding $horizontalPadding;
	margin:0 auto;
	@include max-width(900px){
		padding:20px;
	}
}

article {
	h1 {
		@extend %heading;
		font-size:36px;
		margin:2em 0 .75em;
		color:$light-blue;
		line-height:1.3;
		&:first-child {
			margin-top:1em;
		}
	}
	h2 {
		@extend %heading;
		font-size:24px;
		margin:3em 0 1em;
		color:$light-blue;
	}
	p {
		@extend %body;
		margin:1em 0;
		line-height:1.7;
	}
	ol, ul {
		padding-left:30px;
	}
	ol { list-style:decimal; }
	ul { list-style:disc; }
	li {
		margin-bottom:1em;
		line-height:1.3;
	}
	a {
		color:$light-orange;
	}

}

.error {
	border-left:4px solid $red;
	padding:.5em 1ex;
}

.success {
	border-left:4px solid $green;
	padding:.5em 1ex;
}

.styled-select {
	position: relative;
	cursor: pointer;
	button {
		position: absolute;
		@extend %button;
		color:white;
		background:$evil-grey;
		width:100%;
		left:0;
		top:50%;
		transform:translateY(-50%);
		outline:none;
		pointer-events:none;
		border-radius:8px;
		text-transform: none;
		font-size:14px;
		padding:9px 30px 8px 15px; //TODO magic numbers...
		text-align: left;
		&:after {
			content:"▼";
			font-size:10px;
			position: absolute;
			top:50%;
			right:15px;
			transform:translateY(-50%);
		}
	}
}
.lessonplan, .quicktrip {

	main > header {
		@extend %clearfix;
		margin-bottom:$verticalPadding;
		figure {
			width:50%;
			float: left;
			padding-right:$horizontalPadding;
			img {
				display: block;
				width: 100%;
				border-radius:15px;
			}
		}
		section {
			width:50%;
			float: right;
		}
		h1 {
			@extend %heading;
			margin-bottom:.5em;
			font-size:36px;
			margin-top:1em;
		}
		p {
			@extend %body;
			line-height:1.5;
		}
		menu {
			padding-top:1em;
			border-top:1px solid $light-grey;
			margin-top:2em;
			p {
				font-size:14px;
				font-style: italic;
			}
		}
		#print {
			@extend %button;
			font-size:16px;
			margin-right:10px;
		}
		a {
			color:$dark-orange;
		}
	}
}

.tabs {
	margin-bottom:$verticalPadding;
	.description {
		font-style: italic;
		font-size:14px;
	}
	header {
		ol {
			display: flex;
			li {
				cursor: pointer;
				background:$dark-orange;
				color:white;
				padding:15px 35px;
				font-weight: bold;
				font-size:16px;
				& + li {
					margin-left:10px;
				}
				&:hover {
					background:$light-orange;
				}
				&.active {
					background:#F2F2F2;
					color:$black;
				}
			}
		}
	}
	dl {
		dt {
			display: none;
		}
		dd {
			background:#F2F2F2;
			padding:$verticalPadding $horizontalPadding;
			display: none;
			&:first-of-type {
				display: block;
			}
			section {
				max-width:600px;
				& + section {
					margin-top:3em;
				}
				&.grid {
					max-width:none;
					ul {
						list-style:none;
						padding-left:0;
					}
					.description {
						max-width:600px;
						margin-bottom:2em;
					}
				}
			}
		}
		h3 {
			@extend %heading;
			margin-bottom:.5em;
			font-size:28px;
		}

		h4 {
			font-weight: bold;
			font-size:18px;
		}

		p, ul {
			@extend %body;
			line-height:1.5;
		}
		ul, ol {
			padding-left:30px;
		}
		ul {
			list-style:disc;
		}
		ol {
			list-style:decimal;
		}
		.raw {
			[style~="margin-left:.25in"]{
				margin-left:0 !important;
			}
			[style~="margin-left:.75in"]{
				margin-left:1em !important;
				font-weight: bold;
			}
			[style~="margin-left:1.25in"]{
				margin-left:2em !important;
			}
		}
	}
}
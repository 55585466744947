.social {
	display: flex;
	justify-content: space-between;
	align-items: center;
	li {
		flex-grow:1;
		background-position:center center;
		background-repeat: no-repeat;
		background-size: auto 20px;
		border-radius: 3px;
		&:first-child {
			margin-right:20px;
			position: relative;
			&:after {
				height:100%;
				width:1px;
				position: absolute;
				top:0;
				right:-20px;
				border-radius:2px;
				content:"";
				background:$black;
			}
		}
		& + li {
			margin-left:20px;
		}
		a {
			height:40px;
			overflow: hidden;
			text-indent: -999px;
			display: block;
		}
	}
	.email {
		background-color: #4FC6DF;
		background-image: url(../images/share-email.svg);
		flex-grow:.6;
	}
	.facebook {
		background-color: #3C5B97;
		background-image: url(../images/share-facebook.svg);
	}
	.twitter {
		background-color: #55ACEE;
		background-image: url(../images/share-twitter.svg);
	}
	.google {
		background-color: #DD4B39;
		background-image: url(../images/share-google.svg);
	}
	.instagram {
		background-color: #125688;
		background-image: url(../images/share-instagram.svg);
	}
	.linkedin {
		background-color: #4875B4;
		background-image: url(../images/share-linkedin.svg);
	}
}
.team {
	main {
		img {
			width: 100%;
			display: block;
		}
		& > section {
			@extend %clearfix;
			margin-bottom:$verticalPadding*2;
			@include max-width(767px) {
				margin-bottom: $verticalPadding*2/3;
			}
		}
		.team-overview {
			figure {
				@include max-width(767px){
					display: flex;
					flex-direction: column;
					figcaption {
						order:2;
						width:100%;
						float: none;
					}
					img {
						order:1;
						width:100%;
						float: none;
					}
				}
			}
			figcaption {
				@extend %half;
				padding-right:$horizontalPadding;
				float: left;
				h1 {
					margin-top:1em;
				}
			}
			img {
				@extend %half;
				height:auto;
				float: right;
				border-radius:5px;
			}
		}
		.overview, .team-overview {
			h1 {
				@extend %heading;
				color:$light-blue;
				font-size:36px;
				margin-bottom:.35em;
				line-height:1.15;
			}
			p {
				@extend %body;
				line-height:1.5;
			}
		}
		.overview {
			@extend %half;
			float: left;
			padding-right:$horizontalPadding;
			@include max-width(767px) {
				width:100%;
				float: none;
				margin-bottom:$verticalPadding/2;
			}
			a {
				margin:40px auto 0;
				width:40%;
				display: block;
				&:hover {
					img {
						filter:grayscale(0);
						opacity:1;
					}
				}
			}
			img {
				filter:grayscale(1);
				opacity:.4;
				transition:all .3s;
			}
		}
		.listing {
			@extend %half;
			float: right;
			@include max-width(767px) {
				width:100%;
				float: none;
			}
		}
		ul {
			font-size:14px;
			@extend %clearfix;
			li {
				width:22%;
				margin-top:6%;
				float: left;
				&:nth-child(4n+1){
					clear:both;
				}
				&:not(:nth-child(4n+1)){
					margin-left:3%;
				}
				//do this instead of using margin-bottom so the last row doesn't affect spacing between sections
				//skip the top margin on the first 4 items
				&:nth-child(-n+4){
					margin-top:0;
				}
				@include max-width(600px) {
					width:100%;
					margin:0 0 1em 0 !important;
				}
			}
			img {
				display: block;
				width: 100%;
				height:100%;
				margin-bottom:.5em;
			}
			h2 {
				margin-bottom:.25em;
				font-weight: bold;
			}
			h3 {
				font-size:12px;
				line-height:1.3;
			}
		}
		.corps {
			ul {
				& + ul {
					margin-top:$verticalPadding;
				}
			}
		}
		.partners ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				width:45%;
				margin-bottom:10%;
				margin-left:0;
				@include max-width(767px) {
					margin-bottom:5%;
				}
			}
			a {
				display: block;
			}
			&:after {
				content:none;
			}
		}
		.thanks ul {
			li {
				width:100%;
				float: none;
				& + li {
					margin-left:0;
					margin-top:6%;
				}
			}
		}
	}
}
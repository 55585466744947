#page-header {
	background:$dark-orange;
	@extend %noselect;

	a {
		text-decoration: none;
	}
}

.authenticated #profile {
	@include max-width(500px){
		flex-direction: column;
	}
	a {
		@include max-width(500px){
			margin-top:1em;
			margin-bottom:.5em;
		}
		&.logout {
			@include max-width(500px){
				margin-left:0;
			}
		}
	}
}

.unauthenticated #profile {
	@include max-width(500px){
		.register {
			margin-left:0;
		}
		span {
			display: none;
		}
	}
}

#profile {
	background:$black;
	color:white;
	padding:10px $horizontalPadding;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include max-width(768px){
		padding:10px;
	}

	a {
		color: white;
		&.logout, &.register {
			@extend %button;
			margin-left:auto;
		}
		&.login {
			font-size:16px;
			transition:color .3s;
			&:hover {
				color:$light-orange;
			}
		}
	}
	span {
		margin:0 10px;
		color:$medium-grey;
		font-style: italic;
		font-size:14px;
		@include max-width(500px){
			margin-top:1em;
		}

	}
}

#main-menu {
	padding:40px $horizontalPadding;
	display: flex;
	align-items:center;
	@include max-width(1150px) {
		padding:40px 20px;
	}
	@include max-width(1000px) {
		flex-wrap: wrap;
		justify-content:center;
	}

	#logo {
		flex:none;
		margin-right:30px;
		padding-right:30px;
		position: relative;
		@include max-width(400px) {
			padding:0;
			margin:0;
		}
		&:after {
			content:"";
			height:90%;
			width:3px;
			background:white;
			border-radius:6px;
			position: absolute;
			right:0;
			top:5%;
			@include max-width(400px) {
				display: none;
			}
		}
		img {
			width:200px;
		}
	}
	h1 {
		flex:none;
		color:white;
		font-size:21px;
		line-height:1.3;
		font-weight: bold;
		@include max-width(400px) {
			display: none;
		}
	}
	//TODO switch to a mobile toggle at smaller sizes
	ul {
		margin-left:auto;
		@include max-width(1000px) {
			width:100%;
			margin-top:2em;
			margin-left:0;
			text-align: center;
		}
		li {
			display: inline-block;
			& + li {
				margin-left:40px;
				@include max-width(1150px) {
					margin-left:20px;
				}
			}
			@include max-width(630px) {
				margin-bottom:1em;
			}

			&.current a, a:hover {
				&:after {
					width:100%;
					opacity:1;
				}
			}
		}
		a {
			color:white;
			position: relative;
			&:after {
				width:0;
				transition:all .3s;
				position: absolute;
				height:3px;
				background: $light-orange;
				border-radius:3px;
				top:130%;
				left:50%;
				opacity:0;
				transform:translateX(-50%);
				content:"";
			}
		}
	}
}

//TODO refactor the !important declarations for responsive
//TODO adjust each page to display the header graphic on mobile
#banner {
	@extend %clearfix;
	min-height:400px;
	@include max-width(767px) {
		background-image:none !important;
		min-height:0;
	}

	div {
		max-width:700px;
		padding:80px 120px 80px 0;
		@include max-width(767px) {
			max-width:none;
			padding:40px;
			width:100% !important;
			margin-left:0 !important;
		}
		@include max-width(500px) {
			padding:40px 20px;
		}
	}
	h1 {
		font-size:48px;
		line-height:1.1;
		margin-bottom:.25em;
		font-weight: bold;
	}
	p {
		font-size:21px;
		line-height:1.5;
		color:$black;
	}
}

.home #banner {
	//TODO repace the graphic because Grace says it's bad
	background:#E2F4F8 url(../images/banner-home.svg) 2% bottom no-repeat;
	background-size:48%;
	div {
		color:#098692;
		width:45%;
		margin-left:55%;
	}
}

.about #banner {
	background:#D7ECED url(../images/banner-about.svg) left bottom no-repeat;
	background-size:35%;
	div {
		color:#098692;
		width:60%;
		margin-left:40%;
	}
}

.media #banner {
	background:#FEF1DA url(../images/banner-media.svg) left bottom no-repeat;
	background-size:39%;
	div {
		color:#BB7E13;
		width:60%;
		margin-left:40%;
	}
}

.lessonplans #banner {
	background:#F8F4DD url(../images/banner-lessons.svg) left bottom no-repeat;
	background-size:35%;
	div {
		color:#695C15;
		width:60%;
		margin-left:40%;
	}
}

.resources #banner {
	background:#FBE6DD url(../images/banner-resources.svg) left center no-repeat;
	background-size:35%;
	div {
		color:#AF4D20;
		width:60%;
		margin-left:40%;
	}
}

.roadmaps #banner {
	background:#FEF1DA url(../images/banner-roadmaps.svg) left bottom no-repeat;
	background-size:30%;
	div {
		color:#BB7E13;
		width:60%;
		margin-left:40%;
	}
}

.quicktrips #banner {
	background:#F8F4DD url(../images/banner-quicktrips.svg) left bottom no-repeat;
	background-size:40%;
	div {
		color:#D2B82A;
		width:60%;
		margin-left:40%;
	}
}


#survey-promo {
	background:#0c9dd9;
	position: relative;
	display: none;
	a {
		display: block;
		text-align: center;
		&::before, &::after {
			content:"";
			display: block;
			height:25px;
			background:url(../images/survey-border.svg) left top repeat-x;
			background-size:auto 100%;
		}
	}
	img {
		max-width:800px;
		width:100%;
		vertical-align: text-bottom;
	}
	button {
		cursor: pointer;
		position: absolute;
		top:45px;
		right:10px;
		color:white;
		background:none;
		font-size:36px;
		border:none;
		line-height:0;
		padding:0;
		text-shadow: 0 0 10px #0c9dd9;
	}
}
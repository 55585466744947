.faqs {
	main {
		//TODO maybe add extra padding at the bottom to compensate for the image
		background:url(../images/henry.svg) bottom right no-repeat;
		@extend %clearfix;
		@include max-width(750px) {
			padding-bottom:100px;
		}
		ul {
			list-style:none;
			padding:0;
			width:45%;
			float: left;
			&:nth-child(odd){
				clear: both;
			}
			&:nth-child(even) {
				float: right;
			}
			@include max-width(750px) {
				width:100%;
				float: none;
			}
		}
		li {
			margin-bottom:2em;
		}
		dl {
		}
		dt {
			font-size:24px;
			font-weight: bold;
			margin-bottom:.5em;
			line-height:1.3;
		}
		dd {
			@extend %body;
			line-height:1.7;
		}
	}
}
.contact {
	main {
		@extend %clearfix;
		padding-bottom: $verticalPadding*2;
	}
	.social {
		padding-left:0;
	}
	article {
		width: 50%;
		float: left;
		@include max-width(750px) {
			width:100%;
			float: none;
			margin-bottom:5em;
		}
		ul {
			list-style:none;
			padding-left:0;
		}
	}
	form {
		width:40%;
		float: right;
		@include max-width(750px) {
			width:100%;
			float: none;
		}
		label {
			font-size:14px;
		}
		input, textarea {
			font-size:16px;
			background:$light-grey;
		}
	}
	.message {
		margin:1em 0;
		font-size:16px;
		font-weight: bold;
	}
}
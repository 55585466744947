.media {
	main {
		header {
			padding: $verticalPadding/2 0;
			@extend %clearfix;
			margin-bottom:$verticalPadding;
			h1 {
				font-size:48px;
				line-height:1.1;
				margin-bottom:.25em;
				font-weight: bold;
			}
			p {
				font-size:21px;
				line-height:1.7;
				color:$black;
			}
		}
	}
	.intro {
		width:60%;
		float: left;
	}
	.form {
		width:30%;
		float: right;
		form {
			width:100%;
			margin-top:1em;
		}
		label {
			font-size:14px;
		}
		input {
			background:$light-grey;
			margin-bottom:1.5em;
			&[type=submit] {
				@extend %button;
				float: right;
				margin-bottom:0;
			}
		}
	}
	.news-full {

	}
	.news-columns {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		section {
			width:30%;
			display: inline-block;
			vertical-align: top;
		}
		h1 {
			text-align: center;
		}
	}
	.news-box {
		background:#F4F4F4;
		margin-bottom:$verticalPadding;
		.content {
			padding:20px;
			@extend %clearfix;
		}
		h1 {
			background:$medium-grey;
			text-transform: uppercase;
			color:white;
			padding:20px;
			font-size:18px;
			@extend %noselect;
		}
		h2 {
			font-size:24px;
			font-weight: bold;
			line-height:1.1;
			margin:0 0 .25em;
			color:$black;
		}
		p {
			@extend %body;
			clear: both;
		}
		.button {
			@extend %button;
		}
		article {
			width:70%;
			float: left;
		}
		menu {
			width:25%;
			float: right;
			text-align: right;
			button {
				@extend %button;
				font-size:18px;
			}
		}
	}

	.branding {
		ul {
			list-style:none;
			padding:0;
			display:flex;
			justify-content: space-between;
			align-items: flex-start;
		}
		li {
			width:30%;
			& + li {
			margin-left:20px;
		}
		}
		a {
			text-decoration: none;
		}
		img {
			width:100%;
			display: block;
			background:$dark-grey;
			border-radius:2px;
		}
		figcaption {
			font-size:12px;
			padding-top:.5em;
			background:#F4F4F4;
			color:$black;
			text-align: center;
		}
	}

	.icon {
		height:50px;
		width:50px;
		text-align: center;
		line-height:50px;
		float: left;
		margin:0 15px 5px 0;
		@extend %noselect;
		img {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.facebook .icon { background: #3C5B97; }
	.twitter .icon { background: #55ACEE; }
	.instagram .icon { background: #125688; }
}
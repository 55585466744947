$light-orange: #FAA819;
$dark-orange:#E9662A;
$light-blue:#098692;
$dark-blue:#004851;
$black:#333333;
$light-grey:#e6e6e6;
$medium-grey:#808080;
$evil-grey:#4D4D4D;
$dark-grey: #2b2b2b;
$red:#CD163F;
$green:#8FAC67;
.login, .register, .forgot, .confirmation, .reset {
	background:$black;
	color:white;
	#page-header {
		background:$black;
	}
	#main-menu {
		@include max-width(900px){
			padding:40px 20px 20px;
		}
	}
	#profile, #main-menu ul {
		display: none;
	}
	main {
		@extend %clearfix;
		margin:0;
		@include max-width(750px){
			display: flex;
			flex-direction: column;
		}
	}
	.form {
		@extend %half;
		float: right;
		@include max-width(750px){
			width:100%;
			order:2;
		}
		.forgot {
			font-size:14px;
			margin-top: 2em;
			color:white;
			font-style: italic;
			display: inline-block;
			margin-left:30px;
		}
	}
	.spiel {
		width:45%;
		float: left;
		@include max-width(750px){
			width:100%;
			order:1;
		}
		h1 {
			font-size:48px;
			line-height:1.1;
			margin-bottom:.25em;
			font-weight: bold;
		}
		p {
			font-size:24px;
			line-height:1.7;
			margin-bottom:1em;
			&.cta {
				text-align: center;
				font-style: italic;
				a {
					font-style: normal;
					@extend %button;
					background:$light-blue;
					margin-top:.5em;
				}
			}
			a {
				color:$light-orange;
				font-weight: bold;
			}
		}
		.button {
			@extend %button;
			font-size:21px;
		}
	}
	.form, .spiel {
		.help {
			margin-top:3em;
			padding-left:30px;
			background:url(../images/exclaimation.svg) left center no-repeat;
			background-size:15px auto;
			p {
				font-size:18px;
				line-height:1.3;
			}
			a {
				font-weight: bold;
				color:white;
				text-decoration: none;
			}
		}
	}
	ul {
		font-size:16px;
		margin-bottom:2em;
	}
	#page-footer {
		@include max-width(900px){
			border-top:1px solid $medium-grey;
		}
	}
}

.form {
	form {
		margin-top:2em;
		font-size:21px;
		width:80%;
		@extend %noselect;
		@include max-width(900px){
			width:100%;
		}
		fieldset {
			border-top:1px solid $light-grey;
			margin-bottom:1em;
		}
		legend {
			text-transform: uppercase;
			font-size:14px;
			font-weight: bold;
			display: block;
			margin-bottom:1.5em;
			color:$light-orange;
			padding-right:20px;
		}
		label {
			display: block;
			margin-bottom:.75em;
			cursor: pointer;
		}
		input {
			padding:5px 10px;
			font-size:21px;
			font-family:Gandhi, sans-serif;
			margin-bottom:2em;
			width:100%;
			display: block;
			border:none;
			&.error {
				background:#CD163F;
				color:white;
			}
			&[type=radio], &[type=checkbox] {
				width:auto;
				display: inline;
				margin-bottom:.5em;
				margin-left:0;
				margin-right:.5ex;
				vertical-align: baseline;
			}
			&[type=submit]{
				@extend %button;
				width:auto;
				margin-bottom:1em;
				cursor: pointer;
			}
			&[type=range]{
				//TODO style the range
			}
		}
		textarea {
			padding:10px;
			font-size:21px;
			font-family:Gandhi, sans-serif;
			margin-bottom:2em;
			display: block;
			width:100%;
			height:200px;
			border:none;
		}
		.invalid label {
			color: $red;
		}
		input.invalid {
			background:$red;
		}
	}
	.half {
		float: left;
		margin-right:4%;
		width:48%;
		margin-bottom:2em;
		& + .half {
			margin-right:0;
		}
	}
	.inline-fields {
		margin-bottom:1em;
		display: flex;
		flex-wrap:wrap;
		justify-content: space-between;
		li + li {
			margin-left:8px;
		}
		input {
			display: inline-block;
			vertical-align: middle;
		}
	}
	label.required, span.required {
		&:after {
			content:"*";
			color:$dark-orange;
			margin-left:4px;
		}
	}
	.description {
		font-style: italic;
		font-size:14px;
		margin-bottom:1.5em;
	}
	.number {
		input {
			text-align: right;
			width:80px;
			margin-right:1ex;
			display: inline-block;
		}
	}
	.ajax-search {
		.search {
			position: relative;
			.search-wrapper {
				position: relative;
				&:before {
					content:"";
					display: block;
					background:url(../images/magnifying-glass-dark.svg) left center no-repeat;
					background-size:15px auto;
					height:100%;
					width:20px;
					position: absolute;
					top:0;
					left:10px;
				}
			}
		}
		[type=search] {
			padding-left:30px;
			margin-bottom:1em;
		}
		ul {
			position: absolute;
			z-index:2;
			top:100%;
			width:100%;
			background-color: white;
			color:$black;
			font-size:14px;
			max-height:200px;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			border-top:1px solid gray;
			margin:0;
		}
		li {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			padding:10px 10px 10px 35px;
			& + li {
				border-top:1px solid gray;
			}
			&:hover {
				background:$light-orange;
				color:white;
				address {
					color:white;
				}
			}
		}
		span {
			font-weight: bold;
		}
		address {
			display: inline-block;
			margin-left:1ex;
			font-style: italic;
			color:gray;
			font-size:11px;
		}
	}
	.school-zip-label {
		display: flex;
		#school-citystate-preview {
			align-self:flex-end;
			margin-left:auto;
			font-size:12px;
			color:$light-orange;
		}
		small {
			font-size:12px;
			font-style: italic;
		}
	}

	#manual-school-entry {
		display: none;
		padding:20px 20px 0;
		border:8px solid $light-blue;
		margin-bottom:2em;
		label:not(:first-child){
			font-size:16px;
		}
		input {
			margin-bottom:1.5em;
			font-size:14px;
		}
	}

	.toggle {
		label {
			font-size:16px;
			font-style: italic;
		}
	}

	.range {
		position: relative;
		padding-bottom:20px;
		margin-bottom:1em;
		&:before, &:after {
			position: absolute;
			bottom:0;
			font-size:12px;
		}
		&:before {
			content:attr(data-label-low);
			left:0;
			text-align:left;
		}
		&:after {
			content:attr(data-label-high);
			right:0;
			text-align:right;
		}
		ol {
			position: relative;
			top:0;
			z-index:-1;
			display:flex;
			justify-content: space-between;
			li {
				height:5px;
				width:1px;
				background:gray;
				display: inline-block;
				text-indent:-9999px;
				overflow: hidden;
			}
		}
		input {
			padding:0;
			margin:0;
			cursor: pointer;
		}
	}
}

.login {

}

.register {

}

.error404 {
	background:#B0D8DC;
	main {
		text-align: center;
		h1 {
			@extend %heading;
			text-transform: uppercase;
			color:#054349;
			font-size:48px;
			margin:.5em 0 .25em;
		}
		p {
			@extend %body;
		}
	}
}
.listing {
	main {
		padding:0 $horizontalPadding;
		@include max-width(900px) {
			padding:0 20px;
		}
		header {
			h1 {
				height:70px;
				line-height:70px;
				padding:0 15px;
				font-weight: bold;
				font-size:18px;
			}
		}
	}
}

.grid {
	font-size:14px;
	a {
		text-decoration: none;
	}
	header {
		background:$light-grey;
		position: relative;
		font-size:16px;
		transition:background .5s;
		& ~ ul {
			margin-top:$verticalPadding; //need to put this here instead of on the bottom of the header because the scrollToFixed integration removes the header from the flow
		}
		&:before, &:after {
			content:"";
			background:$light-grey;
			height:100%;
			width:2000px;
			position: absolute;
			top:0;
			transition:background .5s;
			z-index:-1;
		}
		&:before {
			right:100%;
		}
		&:after {
			left:100%;
		}
		&.scroll-to-fixed-fixed {
			background:$light-orange;
			box-shadow: 0 -10px 10px 10px rgba(0,0,0,0.2);
			&:before, &:after {
				background:$light-orange;
			}
			&:before {
				box-shadow: -16px -10px 10px 10px rgba(0,0,0,0.2);
			}
			&:after {
				box-shadow: 16px -10px 10px 10px rgba(0,0,0,0.2);
			}
			form {
				label {
					color:white;
				}
			}
		}
		form {
			display: flex;
			align-items: center;
			@include max-width(800px) {
				padding: 10px 0;
			}
			@include max-width(550px) {
				flex-direction:column;
				* {
					margin:.5em 0 !important;
					width:100%;
				}
			}
			label {
				margin-right:10px;
				font-weight: bold;

				&:after {
					content:":";
				}
			}
			.styled-select {
				margin-right:30px;
			}
			select {
				min-width:150px;
				font-family:Gandhi, sans-serif;
				opacity:0; //hides it to prevent a flash of the unstyled select
			}
			.search {
				position: relative;
				&:before {
					content:"";
					display: block;
					background:url(../images/magnifying-glass.svg) left center no-repeat;
					height:100%;
					width:10px;
					position: absolute;
					top:0;
					left:10px;
				}
			}
			input {
				background:#B3B3B3;
				color:white;
				border:none;
				padding:10px 10px 10px 30px;
				min-width:250px;
				font-size:12px;
				font-family:Gandhi, sans-serif;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					color: white;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					color: white;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					color: white;
				}
				&:-moz-placeholder { /* Firefox 18- */
					color: white;
				}
			}
			a {
				background:$dark-orange;
				align-self: flex-end;
				margin-left:auto;
				color:white;
				padding:15px 100px 15px 15px;
				line-height:1.3;
				position: relative;
				transition:all .3s;
				font-weight: bold;
				@include max-width(800px) {
					display: none;
				}
				&:hover {
					background-color:$light-orange;
				}
				&:after {
					content:"";
					display: block;
					background:url(../images/arrow-right.svg) left center no-repeat;
					height:100%;
					width:10px;
					position: absolute;
					top:0;
					right:15px;
				}
			}
		}
	}
	.listing {
		display:flex;
		justify-content: space-between;
		flex-wrap: wrap;
		li {
			width:30%;
			margin-bottom:5%;
			padding:15px 15px 30px;
			flex:none;
			background:$light-grey;
			position: relative;
			&:last-child:nth-child(3n+1){
				margin-right:auto;
			}
			&:last-child:nth-child(3n+2){
				margin-right:auto;
				margin-left:5%;
			}
			@include max-width(800px) {
				width:49%;
				margin:0 0 2% !important;
			}
			@include max-width(500px) {
				width:100%;
				margin:0 0 2% !important;
			}
		}
		//effectively crop the [image] thumbnails to fit
		.thumb {
			width:100%;
			overflow: hidden;
			padding-top:48.87295082%;
			position: relative;
			margin-bottom:1em;
			img {
				position: absolute;
				top:50%;
				left:0;
				transform:translateY(-50%);
				margin-bottom:0;
			}
		}
		img {
			display: block;
			width:100%;
			margin-bottom:1em;
		}
		h2 {
			font-weight: bold;
			font-size:18px;
			margin-bottom:.5em;
		}
		p {
			@extend %body;
			max-height:200px;
			overflow: hidden;
			text-overflow: ellipsis;
			&:last-child {
				margin-bottom:0;
			}
		}
		a {
			color:$black;
		}
	}

}

.with-type-indicators {
	.listing {
		li {
			&.type1 .thumb:after { //activity
				background-image:url(../images/icons/activity_icon.png);
			}
			&.type2 .thumb:after { //article
				background-image:url(../images/icons/article_icon.png);
			}
			&.type3 .thumb:after { //image

			}
			&.type4 .thumb:after { //video
				background-image:url(../images/icons/video_icon.png);
			}
		}
		.thumb:after {
			position: absolute;
			height:100%;
			width:100%;
			top:0;
			left:0;
			pointer-events: none;
			background-size:auto 50%;
			background-position: center center;
			background-repeat:no-repeat;
			content:"";
			z-index:1;
		}
	}
}

.rows {
	max-width:none;
	padding:0;
	li {
		&:nth-child(even){
			background-color:#E6E6E6;
		}
		div {
			@extend %container;
			padding:$verticalPadding;
			padding-left:220px;
			background:url(../images/roadmap.svg) left center no-repeat;
			background-size:180px auto;
		}
		h2 {
			font-size:24px;
			font-weight: bold;
			margin-bottom:.5em;
		}
		p {
			@extend %body;
			line-height:1.7;
		}
		a {
			@extend %button;
		}
	}
}

.roadmaps {
	.grid li a {
		@extend %button;
		width: 100%;
		font-size:21px;
		text-align: center;
		padding-left:0;
		padding-right:0;
	}
}
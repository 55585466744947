#page-footer {
	position: absolute;
	width: 100%;
	bottom:0;
	left:0;
	background:$black;
	color:#C6C6C6;
	display: flex;
	justify-content: space-between;
	align-content: center;
	font-size:14px;
	padding:20px $verticalPadding;
	@extend %noselect;
	@include max-width(800px){
		position: static;
		flex-direction:column;
		align-items: center;
		padding:20px 5px;
	}
	small {

	}
	ul {
		@include max-width(800px){
			margin-top:1em;
			text-align: center;
		}
	}
	li {
		display: inline-block;
		& + li {
			margin-left:15px;
			border-left:1px solid #C6C6C6;
			padding-left:15px;
			@include max-width(800px){
				border:none;
				padding-left:0;
				margin-left:0;
			}
		}
		@include max-width(800px){
			display: block;
			margin-bottom:1em;
			a {
				color:$light-orange;
			}
		}
		&:hover, &.current {
			a {
				color:$light-orange;
			}
		}
	}
	a {
		color:#C6C6C6;
		text-decoration: none;
	}
}
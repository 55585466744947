.home {

	main {
		@extend %clearfix;
		padding:$verticalPadding*2 0;
		max-width:none;
		@include max-width(900px) {
			padding: $verticalPadding 20px;
		}
	}

	#map {
		width:55%;
		float: left;
		padding-right:80px;
		@extend %clearfix;
		@include max-width(900px) {
			width:100%;
			float: none;
			padding-right:0;
		}
		h1 {
			clear: both;
			float: right;
			margin:1em 0;
			font-size:18px;
			color:$light-blue;
			font-weight: bold;
			width:80%;
			text-align: center;
			@include max-width(900px) {
				width:100%;
				float: none;
			}
		}
	}

	#map-container {
		width:80%;
		padding-top:100%;
		position: relative;
		float: right;
		@include max-width(900px) {
			width:100%;
			float: none;
		}
		#map-frame, iframe {
			border:1px solid $light-blue;
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			left:0;
		}
	}

	#overview {
		width:45%;
		float: right;
		padding-right:120px;
		margin-top:3em;
		@include max-width(900px) {
			width:100%;
			float: none;
			margin-top:0;
			padding-right:0;
		}
		section {
			margin-bottom:5em;
			a {
				@extend %button;
				font-size:24px;
				padding:15px 25px;
			}
		}
		h1 {
			@extend %heading;
			font-size:36px;
			margin-bottom:.5em;
		}
		h2 {
			font-size:21px;
			margin:.5em 0 1em;
			font-style: italic;
			color:$dark-orange;
		}
		p {
			@extend %body;
			font-size:21px;
			line-height: 1.7;
		}
	}

	.share {
		margin:1em 0;
		font-size:21px; //to match the paragraphs above it
	}

}

.home #banner .store-downloads {
	margin-left: 0;
	margin-top: 2em;
	padding-bottom: 0;
	padding-right:0;
	padding-top:2em;
	width:100%;
	.web-app {
		color:white;
		background:$dark-blue;
		display: inline-block;
		margin-bottom: 1em;
		font-size: 24px;
		font-style: normal;
		@extend %button;
	}
	p {
		font-style: italic;
	}
	ul {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 1em;
	}
	li {
		margin:0 20px 20px 0;
		flex:0 0 150px;
	}
	img {
		width:100%;
		display: block;
	}
}